h5 {
  font-size: 16px;
  line-height: 1.2;
}

.react-datepicker__input-container {
  width: 100%;
}

h6 {
  font-size: 14px;
  line-height: 1.2;
}

* {
  font-family: 'GT Walsheim';
}

@font-face {
  font-family: 'GT Walsheim';
  font-style: normal;
  src: url('../../assets/fonts/GT\ Walsheim/GT-Walsheim-Light.woff'),
    url('../../assets/fonts/GT\ Walsheim/GT-Walsheim-Light.ttf'),
    url('../../assets/fonts/GT\ Walsheim/GT-Walsheim-Light.eot?#iefix') format('embedded-opentype');
  font-weight: 100;
}

@font-face {
  font-family: 'GT Walsheim';
  font-style: normal;
  src: url('../../assets/fonts/GT Walsheim/GT-Walsheim-Medium.woff'),
    url('../../assets/fonts/GT Walsheim/GT-Walsheim-Medium.ttf'),
    url('../../assets/fonts/GT Walsheim/GT-Walsheim-Medium.eot?#iefix') format('embedded-opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'GT Walsheim';
  font-style: bold;
  src: url('../../assets/fonts/GT Walsheim/GT-Walsheim-Bold.woff'),
    url('../../assets/fonts/GT Walsheim/GT-Walsheim-Bold.ttf'),
    url('../../assets/fonts/GT Walsheim/GT-Walsheim-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: 600;
}

.no-hover {
  &:hover {
    background-color: transparent !important;
  }
}

.addressCard > .addressSection:nth-last-child(3) + div {
  border: none;
}

.custom-terms {
  h6 {
    font-family: 'GT Walsheim';
    font-weight: 300;
    font-size: 16px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Override phone input */
.PhoneInput {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding-left: 16px;
  padding-top: 20px;
  margin-top: 20px;
  &:hover {
    border-bottom: 2px solid #1f0d33;
  }
  &:focus {
    border-bottom: 2px solid #1f0d33;
  }
  select {
    font-size: 14px;
    option {
      padding: 5px;
    }
  }
  .PhoneInputInput {
    font-size: 16px;
    padding: 10px;
    font-family: 'GT Walsheim';
    font-weight: 100;
    border: 0;
    &:focus {
      outline: 0;
    }
  }
  .PhoneInputCountrySelectArrow {
    border: 1px solid;
    border-top: 0;
    border-left: 0;
  }
}
.phoneInputError {
  font-size: 12px;
  color: #f44336;
  font-weight: bold;
}
